@import "../main/abstracts/index";
.banner {
    &.banner--slider,
    &.banner--video {
        position: relative;
        overflow: hidden;

        @if $header--sticky {
            padding-top: $header__height--mobile;

            @include media-breakpoint-up(lg) {
                padding-top: $header__height--unpinned;
            }

            @include media-breakpoint-up(xxl) {
                padding-top: $header__height;
            }
        }

        &.banner--fullscreen {
            // @if $header--sticky {
            //     max-height: 100vh;
            //     max-height: calc(var(--vh, 1vh) * 100);
            // } @else {
            //     max-height: calc(100vh - #{$header__height--mobile});
            //     max-height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile});
            // }

            // @include media-breakpoint-up(lg) {
            //     @if not $header--sticky {
            //         max-height: calc(100vh - #{$header__height--unpinned});
            //         max-height: calc((var(--vh, 1vh) * 100) - #{$header__height--unpinned});
            //     }
            // }

            // @include media-breakpoint-up(xxl) {
            //     @if not $header--sticky {
            //         max-height: calc(100vh - #{$header__height});
            //         max-height: calc((var(--vh, 1vh) * 100) - #{$header__height});
            //     }
            // }
        }
    }

    &.banner--slider {
        z-index: 3;
        overflow: visible;
        &.banner--fullscreen {
            z-index: 3;
            overflow: visible;
            .splide__track{
                overflow: visible;
            }
            figure {
                position: relative;
                //height: calc(100vh - #{$header__height--mobile} - 64px - 106px);
                //height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile} - 64px - 106px); // less navigation panel & direct links teaser
                height: calc(100vh - #{$header__height--mobile});
                height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile} - 200px); // less direct links teaser
                overflow: hidden;

                @include media-breakpoint-up(lg) {
                    height: calc(100vh - #{$header__height--unpinned});
                    height: calc((var(--vh, 1vh) * 100) - #{$header__height--unpinned} - 70px);
                }

                @include media-breakpoint-up(xxl) {
                    height: calc(100vh - #{$header__height});
                    height: calc((var(--vh, 1vh) * 100) - #{$header__height} - 70px);
                }

                picture {
                    position: absolute;
                    top: 0; left: 0;
                    width: 100%;
                    height: 100%;
                    display: flex;

                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }

        &:not(.banner--fullscreen) {
            figure {
                picture {
                    @include media-breakpoint-between(lg, xxl) {
                        display: flex;
                        height: calc(525 / 1920 * map-get($grid-breakpoints, xxl));
                    }

                    @media (min-width: 1920px) {
                        height: 525px;
                    }

                    img {
                        @include media-breakpoint-between(lg, xxl) {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }

                        @media (min-width: 1920px) {
                            width: 100%;
                            height: 100%;
                            object-fit: cover;
                        }
                    }
                }
            }
        }

        figure {
            picture {
                img {
                    transition: transform 3s cubic-bezier(0.23, 1, 0.32, 1);
                }
            }
        }

        .splide {
            &__slide {
                &.is-active {
                    figure {
                        picture {
                            img {
                                transform: scale(1.05);
                            }
                        }
                    }
                }
            }

            &__footer {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-top: 0;
                height: auto;
                z-index: 1;
                @include media-breakpoint-down(md) {
                    position: absolute;
                    z-index: 10;
                    right: 0; bottom: 0px; left: 0;
                    height: auto;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        right: 0; bottom: -24px; left: 0;

                        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
                        pointer-events: none;
                    }
                }

                @include media-breakpoint-up(lg) {
                    // height: 80px;
                }

                .splide__toggle{
                    width: 150px;
                    height: 150px;
                    position: absolute;
                    left: 0;
                    top: -55px;
                    transform: translateY(-100%);
                    padding: 0;
                    background-color: transparent;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @include transition;
                    span{
                        z-index: 2;
                        width: 110px;
                        height: 110px;
                        border-radius: 55px;
                        background-color: rgba($color__white, 0.85);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        @include transition;

                    }
                    &:before{
                        content: "";
                        z-index: 1;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 130px;
                        height: 130px;
                        border-radius: 65px;
                        background-color: rgba($color__white, 0.35);
                        @include transition;
                    }
                    &:after{
                        content: "";
                        z-index: 1;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -50%);
                        width: 130px;
                        height: 130px;
                        border-radius: 75px;
                        background-color: rgba($color__white, 0.0);
                        @include transition;
                    }

                    img{
                        @include transition;
                        @include recolor($color__primary)
                    }
                    &.is-active .splide__toggle__pause{
                        display: flex;
                    }
                    .splide__toggle__pause{
                        display: none;
                    }
                    &.is-active .splide__toggle__play{
                        display: none;
                    }
                    .splide__toggle__play{
                        display: flex;
                    }
                    @include hover-focus-visible{
                        span{
                            background-color: $color__primary;
                        }
                        &:before{
                            content: "";
                            z-index: 1;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 130px;
                            height: 130px;
                            border-radius: 65px;
                            background-color: rgba($color__white, 0.35);
                        }
                        &:after{
                            content: "";
                            z-index: 1;
                            position: absolute;
                            left: 50%;
                            top: 50%;
                            transform: translate(-50%, -50%);
                            width: 150px;
                            height: 150px;
                            border-radius: 75px;
                            background-color: rgba($color__white, 0.3);
                        }

                        img{
                            filter: $filter__white
                        }
                    }
                    @media(max-width: 600px){
                        width: 96px;
                        height: 96px;
                        top: 0;
                        span{
                            width: 80px;
                            height: 80px
                        }
                        &:before{
                            width: 105px;
                            height: 105px;
                        }
                        &:after{
                            width: 105px;
                            height: 105px;
                        }
                        @include hover-focus-visible{
                            &:before{
                                width: 105px;
                                height: 105px;
                            }
                            &:after{
                                width: 105px;
                                height: 105px;
                            }
                        }

                    }
                }
                .splide__arrow--next{
                    position: absolute;
                    right: 0;
                    top: 0;
                    transform: translateY(-50%);
                    width: 58px;
                    height: 58px;
                    border-radius: 29px;
                    background-color: $color__primary;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    -webkit-box-shadow: 0px 4px 10px 6px rgba(0,0,0,0.1);
                    box-shadow: 0px 4px 10px 6px rgba(0,0,0,0.1);
                    img{
                        // @include recolor($color__primary);
                        max-width: 21px
                    }
                    @include hover-focus-visible{
                        background-color: $color__primary--hover;
                        img{
                            filter: $filter__white
                        }
                    }
                    @include media-breakpoint-down(md) {
                        top: 70px;

                    }
                }
                .splide__arrow--prev{
                    position: absolute;
                    right: 60px;
                    top: 0;
                    transform: translateY(-50%);
                    width: 58px;
                    height: 58px;
                    border-radius: 29px;
                    background-color: $color__white;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    -webkit-box-shadow: 0px 4px 10px 6px rgba(0,0,0,0.1);
                    box-shadow: 0px 4px 10px 6px rgba(0,0,0,0.1);
                    img{
                        @include recolor($color__primary);
                        max-width: 21px;
                        transform: rotate(0);
                    }
                    @include hover-focus-visible{
                        background-color: $color__primary--hover;
                        img{
                            filter: $filter__white
                        }
                    }
                    @include media-breakpoint-down(md) {
                        top: 70px;

                    }
                }
            }

            &__pagination {
                @include media-breakpoint-down(md) {
                    padding-bottom: 20px;
                }
                button {
                    @include media-breakpoint-down(md) {
                        &::before {
                            border-color: $color__white;
                        }
                    }

                    &.is-active {
                        @include media-breakpoint-down(md) {
                            &::before {
                                background-color: $color__white;
                                border-color: $color__white;
                            }
                        }
                    }
                }
            }
            &__navigation{
                padding: 20px 0;
            }
        }
    }

    &.banner--video {
        &.banner--fullscreen {
            figure {
                //height: calc(100vh - #{$header__height--mobile} - 64px - 106px);
                //height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile} - 64px - 106px); // less navigation panel & direct links teaser
                height: calc(100vh - #{$header__height--mobile} - 106px);
                height: calc((var(--vh, 1vh) * 100) - #{$header__height--mobile} - 106px); // less direct links teaser

                @include media-breakpoint-up(lg) {
                    height: calc(100vh - #{$header__height--unpinned} - 80px);
                    height: calc((var(--vh, 1vh) * 100) - #{$header__height--unpinned} - 80px);
                }

                @include media-breakpoint-up(xxl) {
                    height: calc(100vh - #{$header__height} - 80px);
                    height: calc((var(--vh, 1vh) * 100) - #{$header__height} - 80px);
                }
            }
        }

        &:not(.banner--fullscreen) {
            figure {
                padding-top: 75%;
                height: 0;

                @include media-breakpoint-up(md) {
                    padding-top: percentage(calc(525 / 1920 * strip_unit(map-get($grid-breakpoints, xxl)) / strip_unit(map-get($grid-breakpoints, lg))));
                }

                @include media-breakpoint-between(lg, xxl) {
                    padding-top: 0;
                    height: calc(525 / 1920 * map-get($grid-breakpoints, xxl));
                }

                @include media-breakpoint-up(xxl) {
                    padding-top: 27.34375%;
                    height: 0;
                }

                @media (min-width: 1920px) {
                    padding-top: 0;
                    height: 525px;
                }
            }
        }

        figure {
            position: relative;
            overflow: hidden;

            video {
                position: absolute;
                top: 0; left: 0;
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }

        .banner {
            &__footer {
                display: flex;
                flex-direction: row;
                align-items: center;
                height: 64px;

                @include media-breakpoint-down(md) {
                    position: absolute;
                    z-index: 10;
                    right: 0; bottom: 24px; left: 0;
                    height: auto;

                    &::before {
                        content: "";
                        position: absolute;
                        z-index: -1;
                        right: 0; bottom: -24px; left: 0;
                        height: 120px;
                        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, .5));
                        pointer-events: none;
                    }
                }

                @include media-breakpoint-up(lg) {
                    height: 80px;
                }

                button {
                    span {
                        &.icon {
                            &[class*=icon--] {
                                display: unset;
                                margin-right: 0;
                            }
                        }
                    }
                }

            }
        }
    }
}
